import Swiper from 'swiper';
import AOS from 'aos';

document.addEventListener('DOMContentLoaded', () => {

    AOS.init({
        duration: 1000,
        easing: 'ease',
        once: true,
    });

    $(document).foundation();

    const nav = document.querySelector('.main-menu');
    const menuBtn = document.querySelector('.menu-btn');
    const lines = document.querySelectorAll('.menu-btn .line');


    // Toggle navigation menu
    menuBtn.addEventListener('click', () => {
        nav.classList.toggle('open');
        lines.forEach((line, index) => {
            line.classList.toggle('line-cross');
            if (index === 1) line.classList.toggle('line-fade-out');
        });
    });

    const ul = document.querySelectorAll("nav#site-navigation ul li ul.children");
    const openSearchBtn = document.querySelector('.open-search-from');
    const deleteSearchBtn = document.querySelector('#deleteSearch');
    const videoContainer = document.querySelector('.video-container');

    // Check if an element is scrolled into view
    const isScrolledIntoView = elem => {
        const docViewTop = window.scrollY;
        const docViewBottom = docViewTop + window.innerHeight + 200;
        const elemTop = elem.offsetTop;
        const elemBottom = elemTop + elem.offsetHeight;
        return elemBottom <= docViewBottom && elemTop >= docViewTop;
    };

    // Handle sticky header on scroll
    const checkScroll = () => {
        const header = document.querySelector('.site-header');
        window.scrollY > 0 ? header.classList.add('scrolled') : header.classList.remove('scrolled');
    };

    // Run the scroll check on page load and on scroll events
    checkScroll();
    window.addEventListener('scroll', checkScroll);

    // Mark parents with children in the navigation
    ul.forEach(ul => {
        ul.parentNode.classList.add('hasChildren');
        const expandLink = "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>";
        ul.parentNode.querySelector('a').insertAdjacentHTML('beforeend', expandLink);
    });

    // Close navigation if clicked outside
    document.addEventListener('click', e => {
        if (!nav.contains(e.target)) nav.classList.remove('open-nav');
    });

    // // Open search bar
    // openSearchBtn.addEventListener('click', () => {
    //     document.querySelector('.top-search').classList.add('open-search');
    //     document.body.classList.add('search-main');
    // });
    //
    // // Close search bar
    // deleteSearchBtn.addEventListener('click', () => {
    //     document.querySelector('.top-search').classList.remove('open-search');
    //     document.body.classList.remove('search-main');
    // });

    // Video play functionality
    if (videoContainer) {
        const playButton = videoContainer.querySelector('.play-button');
        const iframe = videoContainer.querySelector('iframe');
        playButton?.addEventListener('click', () => {
            playButton.style.display = 'none';
            iframe.style.display = 'block';
            iframe.src += "?autoplay=1";
        });
    }

    jQuery('.footer-label').click(function ($) {
        jQuery(this).closest('.footer-menu').find('.footer-container ul').slideToggle();
        jQuery(this).closest('.footer-menu').find('i').toggleClass('open');
    });

    var gallerySlider = new Swiper(".gallery-slider", {
        direction: "horizontal",
        slidesPerView: 2,
        spaceBetween: 10,
        keyboard: {
            enabled: true,
        },
    });
});
